<template>
    <div class="card">
      <div class="card-body pt-0">UPS Shipments</div>
    </div>
  </template>
  
  <script lang="ts">
  import { Actions } from "@/store/enums/StoreEnums";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { defineComponent, onMounted, ref } from "vue";
  import { useStore } from "vuex";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  
  export default defineComponent({
    name: "ups-shipments",
    components: {},
    setup() {
      const store = useStore();
      const currentUser = store.getters.currentUser;
      const isDataReady = ref(false);
  
      onMounted(() => {
        setCurrentPageBreadcrumbs("Shipments", ["UPS"]);
      });
      console.log("store");
      store
        .dispatch(Actions.GET_UPS_SHIPMENT)
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem getting shipment.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
  
      return {
        currentUser,
        isDataReady,
      };
    },
  });
  </script>
  